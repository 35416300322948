<template>
    <div class="container-fluid">
        <div class="row">
            <div class="card">
                <div class="card-header d-flex justify-content-between">
                    <div class="header-title">
                        <h4 class="card-title">Editar fornecedor</h4>
                    </div>
                </div>
                <div class="card-body">
                    <div class="new-user-info">
                        <form @submit.prevent="onSubmit()">
                            <div class="row">
                                <div class="form-group col-md-6">
                                    <label for="fname">Nome:</label>
                                    <input 
                                        type="text" 
                                        v-model="supplierDetail.Nome" 
                                        class="form-control"
                                        :class="`form-control ${$v.supplierDetail.Nome.$error ? 'is-invalid' : ''}`" 
                                        id="name" 
                                        placeholder="Nome"
                                        @input="$v.supplierDetail.Nome.$touch()" 
                                        @blur="$v.supplierDetail.Nome.$touch()"
                                    >
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="phoneNumber">Contacto Telefónico:</label>
                                    <input 
                                        type="text" 
                                        v-model="supplierDetail['Contacto Telefónico']" 
                                        class="form-control"
                                        :class="`form-control ${$v.supplierDetail['Contacto Telefónico'].$error ? 'is-invalid' : ''}`" 
                                        id="phoneNumber" 
                                        placeholder="Contacto Telefónico"
                                        @input="$v.supplierDetail['Contacto Telefónico'].$touch()" 
                                        @blur="$v.supplierDetail['Contacto Telefónico'].$touch()"
                                    >
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="email">Contacto E-mail:</label>
                                    <input 
                                        type="text" 
                                        v-model="supplierDetail['Contacto E-mail']" 
                                        class="form-control"
                                        :class="`form-control ${$v.supplierDetail['Contacto E-mail'].$error ? 'is-invalid' : ''}`" 
                                        id="email" 
                                        placeholder="Contacto E-mail"
                                        @input="$v.supplierDetail['Contacto E-mail'].$touch()" 
                                        @blur="$v.supplierDetail['Contacto E-mail'].$touch()"
                                    >
                                </div>                          
                            </div>
                            <hr>
                            <button type="submit" class="btn btn-primary">Atualizar</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
     </div>
</template>
<script>
import { validationMixin } from 'vuelidate'
import { required, email } from 'vuelidate/lib/validators'
export default {
    name:'SupplierEdit',
    mixins: [validationMixin],
    validations: {
        supplierDetail: {
            Nome: {required},
            'Contacto Telefónico': { required },
            'Contacto E-mail':{ required, email },
        }
    },
    data() {
        return {
            supplierDetail: {},
            suppliers: JSON.parse(localStorage.getItem('suppliers'))
        }
    },
    mounted () {
        if(this.$route.params.supplier) {
            this.supplierDetail = this.$route.params.supplier;
        }
        else{
            this.$router.push({name:'app.suppliers-list'});
        }
   },
    methods:{
        onSubmit()
        {
            let suppliers = JSON.parse(localStorage.getItem('suppliers'));
            this.$v.$touch()
            if (!this.$v.$invalid) {
                for(let index in suppliers) {
                    if(suppliers[index].id === this.supplierDetail.id) {
                        suppliers[index] = {Nome: this.supplierDetail.Nome, 'Contacto Telefónico': this.supplierDetail['Contacto Telefónico'], 'Contacto E-mail': this.supplierDetail['Contacto E-mail']}
                        localStorage.setItem('suppliers', JSON.stringify(suppliers));
                        break;
                    }
                }
                this.$router.push({name:'app.suppliers-list'})
            }
        }
    }
}
</script>